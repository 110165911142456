<template>
  <div class="card">
    <div class="card-body">
      <div class="col-12 mt-2">
        <div
          v-if="alert"
          role="alert"
          aria-live="polite"
          aria-atomic="true"
          :class="changeClasss ? 'alert alert-success' : 'alert alert-danger'"
        >
          {{ alertText }}
        </div>
        <h4 class="card-title mb-5">Tax</h4>

        <div
          style="display:flex;justify-content:space-between;align-items:center;"
        >
          <div style="width:50%;margin-left:10px">
            <b-form-group
              id="input-group-2"
              label="Tax Percentage:"
              label-for="input-2"
            >
              <b-form-input
                v-model="tax_percentage"
                type="text"
                placeholder="Tax Percentage"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div style="display:flex;align-items:center;">
          <div style="width:10%;padding-left:10px">
            <a class="btn btn-success" @click="searcResultApi()">Save</a>
          </div>
        </div>
        <hr />
        <div
          v-if="loading"
          class="d-flex justify-content-center align-items-center"
          style="height:100vh"
        >
          <img
            :src="'./images/loader.gif'"
            class="mr-2"
            style="width:100px;height:100px"
          />
        </div>

        <div class="loading" v-if="!loading">
          <div class="row mb-md-2">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select>
                  entries
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
          </div>
          <div>
            <div class="table-responsive mb-0">
              <b-table
                id="facture12"
                :items="loans"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                ref="selectableTable"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                show-empty
                empty-text="No record found"
                empty-filtered-text="No search result found"
              >
                <template v-slot:cell(id)="meunItemsTempArray">
                  <input
                    type="checkbox"
                    :value="meunItemsTempArray.item.id"
                    v-model="checkedNames"
                  />
                </template>
                <template v-slot:cell(payment_type)="loans">
                  <p v-if="loans.item.payment_type == 1">Online</p>
                  <p v-if="loans.item.payment_type == 0">Cash</p>
                </template>
                <template v-slot:cell(order_status)="loans">
                  <p>
                    {{
                      loans.item.order_status.charAt(0).toUpperCase() +
                        loans.item.order_status.substring(1)
                    }}
                  </p>
                </template>
              </b-table>
            </div>
          </div>
        </div>
        <br /><br />
        <!-- <div id="prinPdf">
          <h3>Hello, this is a H3 tag</h3>
          <a class="upload">Upload to Imgur</a>
          <h2>this is <b>bold</b> <span style="color:red">red</span></h2>
          <p>
            Feedback form with screenshot This script allows you to create
            feedback forms which include a screenshot, created on the clients
            browser, along with the form. The screenshot is based on the DOM and
            as such may not be 100% accurate to the real representation as it
            does not make an actual screenshot, but builds the screenshot based
            on the information available on the page. How does it work? The
            script is based on the html2canvas library, which renders the
            current page as a canvas image, by reading the DOM and the different
            styles applied to the elements. This script adds the options for the
            user to draw elements on top of that image, such as mark points of
            interest on the image along with the feedback they send. It does not
            require any rendering from the server, as the whole image is created
            on the clients browser. No plugins, no flash, no interaction needed
            from the server, just pure JavaScript! Browser compatibility Firefox
            3.5+ Newer versions of Google Chrome, Safari & Opera IE9
          </p>
        </div> -->

        <div class="row">
          <div class="col">
            <div class="dataTables_paginate paging_simple_numbers float-right">
              <ul class="pagination pagination-rounded mb-0">
                <!-- pagination -->
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                ></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/0.9.0rc1/jspdf.min.js"></script>
<script>
import store from "@/core/services/store/index.js";

import axios from "axios";
import Multiselect from "vue-multiselect";
// import { jsPDF } from "jspdf";

import jsPDF from "jspdf";
// import html2canvas from "html2canvas";
// import html2pdf from "html2pdf.js";
// import autoTable from "jspdf-autotable";
// import domtoimage from "dom-to-image";
import $ from "jquery";

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      restaurants: [],
      showRestaurantUserDiv: false,
      changeClasss: false,
      alert: false,
      tax_percentage: 0,
      pickUpOrderAmount: 0,
      numberOfOrders: 0,
      totalPriceOfOrders: 0,
      onLineOrderAmount: 0,
      alertText: "",
      restaurant: "",
      restaurantuser: "",
      restaurantusers: [],
      loans: [],
      tempArray: [],
      newArray: [],
      loading: false,
      startDate: "",
      startPage: 0,
      endPage: 0,
      endDate: "",
      isSelectAll: false,
      sortDesc: true,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      fields: [
        // { key: "date", label: "Date" },
        // { key: "payment_gateway", label: "Payment gateway" },
        { key: "tax_percentage", label: "Tax percentage" },
        { key: "date", label: "Date" },
      ],
      paymentgateWay: "",
      paymentgateWays: [
        { text: "Select Payment gateway", value: "" },
        { text: "Stripe", value: "stripe" },
        { text: "Paypal", value: "paypal" },
      ],
      orderStatus: "",
      orderStatuses: [
        { text: "Select Order Status", value: "" },
        { text: "Pending", value: "pending" },
        { text: "Accepted", value: "accepted" },
        { text: "Rejected", value: "rejected" },
        { text: "Cooking", value: "cooking" },
        { text: "Ready to pickup", value: "readytopickup" },
        { text: "Dispatched", value: "dispatched" },
        { text: "Completed", value: "completed" },
      ],
    };
  },

  mounted() {
    document.title = "Tax";
    if (
      store.state.loginUserType == "superadmin" ||
      store.state.loginUserType == "admin"
    ) {
      this.showRestaurantUserDiv = true;
    }
    this.getRecord();
    this.getAllRestaurant();
    this.getRestaurnatUser();
    this.isSelectAll = this.unSelectedCheckbox;
  },

  computed: {
    rows() {
      return this.loans.length;
    },
  },

  methods: {
    generatePDF() {
      var doc = new jsPDF();
      var specialElementHandlers = {
        "#editor": function(element, renderer) {
          return true;
        },
      };
      doc.fromHTML($("#prinPdf").html(), 15, 15, {
        width: 170,
        elementHandlers: specialElementHandlers,
      });
      doc.save("sample-file.pdf");
    },

    clearFiled() {
      this.startDate = "";
      this.endDate = "";
      this.restaurant = "";
      this.restaurantuser = "";
      this.orderStatus = "";
      this.paymentType = "";
    },

    onSelectRestaurantUser(option) {
      (this.restaurant = ""), (this.restaurants = []);
      this.restaurants = option.restaurnats;
    },

    searcResultApi() {
      if (this.tax_percentage == 0) {
        this.validationMessage("Please select Tax Percentage");
        return false;
      }

      this.loading = true;
      this.alert = false;
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("id_token"),
      };
      const data = {
        tax_percentage: this.tax_percentage,
      };
      try {
        axios
          .post(this.$path + "/user/tax", data, {
            headers,
          })
          .then((response) => {
            this.getRecord();
            this.loading = false;
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    findorderStatus() {
      return this.tempArray.filter((item) => {
        return item.order_status == this.orderStatus;
      });
    },
    findendDate() {
      let allCampaigns;
      allCampaigns = this.tempArray.map(
        (item) => item.order_status === this.orderStatus
      );
      return allCampaigns;
    },

    // searchData() {
    //   if (this.orderStatus) {
    //     return this.tempArray.filter((item) => {
    //       return item.order_status == this.orderStatus;
    //     });
    //   } else {
    //     return this.tempArray;
    //   }
    // },

    filteredPeople() {
      return this.tempArray
        .filter((item) => item.date >= this.startDate)
        .filter((item) => item.date <= this.endDate)
        .filter((item) => item.order_status === this.orderStatus);
    },

    filteredItems: function() {
      return this.tempArray.filter(function(item) {
        return item.date >= this.startDate || item.date <= this.endDate;
      });
    },

    // filteredPeople() {
    //   return this.tempArray.filter(
    //     (item) => item.order_status === this.orderStatus
    //   );
    // },

    filteredData() {
      const res = this.tempArray.filter((item) => {
        if (this.startDate) if (item.date < this.startDate) return false;

        if (this.endDate) if (item.date > this.endDate) return false;

        if (this.restaurant)
          if (item.restaurant.id !== this.restaurant.id) return false;

        if (this.restaurantuser)
          if (item.restaurant.customer_id !== this.restaurantuser.id)
            return false;

        if (this.paymentType)
          if (item.payment_type !== this.paymentType) return false;

        if (this.orderStatus)
          if (
            item.order_status.trim().toLowerCase() !==
            this.orderStatus.trim().toLowerCase()
          )
            return false;

        return true;
      });
      return res;
    },

    searchResult() {
      this.loans = this.filteredData();
    },
    validationMessage(message) {
      this.alert = true;
      this.alertText = message;
      this.changeClasss = false;
    },

    getRecord() {
      this.loading = true;
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("id_token"),
      };
      try {
        axios
          .get(this.$path + "/user/taxList", {
            headers,
          })
          .then((response) => {
            this.loans = response.data.data;
            this.loading = false;
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    getAllRestaurant() {
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("id_token"),
      };
      try {
        axios
          .post(this.$path + "/user/restaurant/list", "", {
            headers,
          })
          .then((response) => {
            this.restaurants = response.data.data;
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    getRestaurnatUser() {
      const data = { type: "restaurant" };
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("id_token"),
      };
      try {
        axios
          .post(this.$path + "/user/list", data, {
            headers,
          })
          .then((response) => {
            this.restaurantusers = response.data.data;
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },
    customLabel({ name, location }) {
      return `${name}—(${location})`;
    },
    restaurantUserLable({ username, email }) {
      return `${username}—(${email})`;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    singlActiveDeactive(object) {
      this.$emit("showModal", true, object);
    },

    deleterRecord(object) {
      this.$emit("deleteData", object);
    },

    editData(object) {
      this.$emit("editData", object);
    },

    orderDetail(object) {
      this.$emit("orderDetail", object);
    },

    changeClass(loans) {
      if (loans.item.status == 1) {
        return "btn btn-info btn-sm";
      } else {
        return "btn btn-primary btn-sm";
      }
    },
    changeVerfiedtext(loans) {
      if (loans.item.status == 1) {
        return "Active";
      } else {
        return "Deactive";
      }
    },
  },

  watch: {
    currentPage: function(value) {
      this.startPage = parseInt((value - 1) * this.perPage);
      if (this.startPage == 0) {
        this.startPage = 1;
      }
      this.endPage = parseInt(value * this.perPage);
    },

    perPage: function(value) {
      this.startPage = parseInt((this.currentPage - 1) * value);
      if (this.startPage == 0) {
        this.startPage = 1;
      }
      this.endPage = parseInt(this.currentPage * value);
    },
  },
};
</script>

<style scoped>
.btn-success {
  border-color: #5cb700;
  background-color: rgb(92, 183, 0);
}
.btn.btn-success:hover {
  border-color: #5cb700 !important;
  background-color: rgb(92, 183, 0) !important;
}
.btn-info {
  background-color: #697165;
  border-color: #697165;
}
.btn-info:hover {
  border-color: #697165 !important;
  background-color: #697165 !important;
}
.btn-primary {
  border-color: #949b8f !important;
  background-color: #949b8f !important;
}
.btn-primary:hover {
  border-color: #949b8f !important;
  background-color: #949b8f !important;
}
.btn-primary:active {
  border-color: #949b8f !important;
  background-color: #949b8f !important;
}

.btn-info:active {
  border-color: #697165 !important;
  background-color: #697165 !important;
}

.btn-primary:focus {
  border-color: #949b8f !important;
  background-color: #949b8f !important;
}

.btn-info:focus {
  border-color: #697165 !important;
  background-color: #697165 !important;
}
</style>
